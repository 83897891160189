import { Injectable,Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import * as CryptoJS from 'crypto-js'; 
import { environment } from '../../../environments/environment';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private injector: Injector,private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

   
    // add auth header with jwt if user is logged in and request is to the api url

    const token=localStorage.getItem("token")
    // if(!token)
    // {
    //   this.router.navigate(['/identity'])
    // }
    //var tokenEncoded= CryptoJS.AES.decrypt(token,"123").toString();  
   
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isApiUrl) {
      request = request.clone({
        setHeaders: {
         'Authorization': `Bearer `+token,
        },
      });
    } 
   
    
    return next.handle(request).pipe(catchError(x=> this.handleAuthError(x)));
    //return next.handle(request);
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
     debugger;
    if (err.status === 401 || err.status === 403 || err.status === 0) {

        localStorage.removeItem('ID');
        localStorage.removeItem('PartnerName');
        localStorage.removeItem('token');
        localStorage.removeItem('paging'); 
        this.router.navigate(['/identity'])
        return throwError(err);
        // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
       // return of(err.message); // or EMPTY may be appropriate here
    }
    return throwError(err);
}

}
