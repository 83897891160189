import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/core/models';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js'; 

@Injectable({ providedIn: 'root' })
export class AccountService {
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();


  changeMessage(message: string) {
    this.messageSource.next(message)
  }
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  constructor(private router: Router, private http: HttpClient) {
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  login(ID, source) {
    //  const source= localStorage.getItem("ticketSource");
    return this.http
      .post<any>(`${environment.apiUrl}/api/Authuntication/Login`, {
        ID,
        source,
      })
      .pipe(
        map((data) => {

          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('ID', ID);
          localStorage.setItem('PartnerName', data.data.PartnerName);


          return data;
        })
      );
  }

  loginAD(userName, password) {
    const source = localStorage.getItem("ticketSource");
    const request = {
      Source: source,
      UserName: userName,
      password: password
    }
    return this.http
      .post<any>(`${environment.apiUrl}/api/Authuntication/LoginAD`, request)
      .pipe(
        map((data) => {
          localStorage.setItem('ID', data.data.ID);
          localStorage.setItem('PartnerName', data.data.PartnerName);
          return data;
        })
      );
  }
  checkOtp(otp: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/api/Authuntication/OtpValidation/`, { ID: localStorage.getItem('ID'), OTP: otp,Source: localStorage.getItem('ticketSource') })
      .pipe(
        map((data) => {
           
         // var tokenEncoded= CryptoJS.AES.encrypt(data.data.token,"123").toString();  
          localStorage.setItem('token', data.data.token);

          return data;
        }));
  }

  ResendOtp() {
    var id = localStorage.getItem("ID");
    return this.http
      .get<any>(`${environment.apiUrl}/api/Tickets/${id}/otp`)
      .pipe(
        map((data) => {

          localStorage.setItem('token', data.data.token);
          return data;
        }));
  }

  logOut() {

    const id = localStorage.getItem("ID")
    
    return this.http
      .post<any>(`${environment.apiUrl}/api/Authuntication/LogOut`, { id: id })
      .pipe(
        map((data) => {
         
        })
      ).subscribe(
        (data) => {
          localStorage.removeItem('Source');
          localStorage.removeItem('ticketSource');
          localStorage.removeItem('ID');
          localStorage.removeItem('PartnerName');
          localStorage.removeItem('token');
          localStorage.removeItem('paging'); return data;
        },
        (err) => {
        
        }
      );
      }
     


}
