export class AppSettings {
  public static readonly defaultLang = 'ar-SA';
  public static readonly fileMaxSize = '3145728';
  public static readonly ticketFileMaxSize = '3145728';
  public static readonly imgMaxSize = '3145728 ';
  public static readonly timeToResendOtpInMS = 1;
  public static readonly allowedExtensions = [
    'pdf',
    'png',
    'jpeg',
    'jpg',
    'xlsx',
    'xls',
    'doc',
    'docx',
    'ppt',
    'pptx',
    'txt',
    'pdf'
  ];
  public static readonly allowedExtensionsImg = [
    'png',
    'jpeg',
    'jpg'
  ];
  public static readonly allowedExtensionsNoImg = [
    'xlsx',
    'xls',
    'doc',
    'docx',
    'ppt',
    'pptx',
    'txt',
    'pdf'
  ];
  public static readonly allowedContactExtensions = ['xlsx', 'csv'];
}
