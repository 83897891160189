<button [mat-dialog-close]="true" type="button" class="close" aria-label="Close">
  <span aria-hidden="true">&times;</span>
</button>

<img class="img_attached" [src]="generatedImage" *ngIf="canPreview && isImage">

<iframe [src]="generatedImage" type="application/pdf" *ngIf="canPreview && !isImage" width="100%" height="100%"
  style="overflow: auto;">
</iframe>

<div class="col mt-5 text-center" *ngIf="!canPreview && !isImage">
  <div class="w-100 ">
    <div class="p-3 opt">
      <img class="mb-4" src="../assets/attention-blue.png" />
      <h4 class="mb-5 ">
        {{'NoPreviewAbailable'| translate}}
      </h4>
      <a [href]="generatedImage" [download]="fileName">
        <button class="download_btn ng-star-inserted" type="button">
          <img class="" src="../.../../../../../assets/download.svg">
          {{'Download'| translate}}</button>
      </a>
    </div>
  </div>
</div>