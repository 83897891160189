import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class LookupService {

  constructor(private router: Router, private http: HttpClient) {
  }


  GetSupType() {
    const source = localStorage.getItem("ticketSource");
    const ID = localStorage.getItem("ID")
    const params = `?ID=${ID}&Source=${source}`
    return this.http
      .get<any>(`${environment.apiUrl}/api/Lookups/SubTypes${params}`,)
      .pipe(
        map((data) => {

          return data;
        })
      );
  }

  GetSupTypeCateogory(SupType) {
    const source = localStorage.getItem("ticketSource");
    const ID = localStorage.getItem("ID")
    const params = `?ID=${ID}&Source=${source}&subtype=${SupType}`
    return this.http
      .get<any>(`${environment.apiUrl}/api/lookups/SubTypeCategories${params}`,)
      .pipe(
        map((data) => {

          return data;
        })
      );
  }

  GetTicketTypes() {

    return this.http
      .get<any>(`${environment.apiUrl}/api/lookups/TicketTypes`,)
      .pipe(
        map((data) => {

          return data;
        })
      );
  }


}
