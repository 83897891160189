import { Component, OnInit } from '@angular/core';

import {
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SurveyService } from 'src/app/services/survey.service';
@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit {
  form: FormGroup;
  question2: string = '';
  question1: string = '';
  enhancementNote: string = '';
  lang = localStorage.getItem('lang');

  constructor(private formBuilder: FormBuilder,
     private spinner: NgxSpinnerService,
     private SurveyService:SurveyService,
     private route: ActivatedRoute,
     private router: Router,
     private translate: TranslateService,
     private Toaster: ToastrService) {
   
  }
initialize(){
  this.form = this.formBuilder.group({
    question2: [''],
    question1: [''],
    enhancementNote: [''],
  });
}
  ngOnInit(): void {
  
    this.initialize();
    this.route.queryParams.subscribe(params => {
       let token = params['token'];
      this.checkSurvey(token)
     
  });
  }

  get f() {
    return this.form.controls;
  }
checkSurvey(token){
  this.SurveyService.checkSurvey(token).subscribe((res)=>{
 this.spinner.hide();
  },  (err) => {
    if(err.status==401)
    {
      window.location.reload()
    }

    this.Toaster.error(
  
      this.lang == 'ar-SA'
      ? err?.error?.arabicMessage
      : err?.error?.englishMessage
  );
  
  this.spinner.hide();
   
  })
}
  submit() {
    if(this.form.value) {
      console.log(this.form.value);
       
      this.SurveyService.postSurvey(this.form.value).subscribe((res)=>{
        this.Toaster.success(
         
        this.translate.instant('Your Survey Has Been Submited Successfuly')
      );
        this.spinner.hide();
         },  (err) => {
           if(err.status==401)
           {
             window.location.reload()
           }
       
           this.Toaster.error(
         
             this.lang == 'ar-SA'
             ? err?.error?.arabicMessage
             : err?.error?.englishMessage
         );
         
         this.spinner.hide();
          
         })
   
    }
  }

}
