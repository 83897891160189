import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from 'src/app/core/settings/app-settings';
import { DOCUMENT } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ui-template';
  currentLang;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
  ) {

  }
  ngOnInit(): void {
    let lang = localStorage.getItem("lang");
    if (lang == null) {
      lang = AppSettings.defaultLang
    }
    this.currentLang = lang;
    localStorage.setItem('lang', this.currentLang);
    this.changeLangage(this.currentLang);
  }


  changeLangage(lang) {
    
    this.spinner.show()
    lang = lang == "en-US" ? "en-US" : "ar-SA";
    const htmlTag = this.document.getElementsByTagName(
      'html'
    )[0] as HTMLHtmlElement;
    lang == "en-US" ? document.title = 'Beneficiaries Support | Education and Training Evaluation Commission' :
      "دعم المستفيدين | لهيئة تقويم التعليم والتدريب"
    htmlTag.dir = lang === 'ar-SA' ? 'rtl' : 'ltr';
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.currentLang = lang;
    localStorage.removeItem('paging');
    localStorage.setItem('lang', lang);
    this.changeCssFile(lang)
  }

  changeCssFile(lang: string) {
    const headTag = this.document.getElementsByTagName(
      'head'
    )[0] as HTMLHeadElement;
    const existingLink = this.document.getElementById(
      'langCss'
    ) as HTMLLinkElement;
    const bundleName = lang === 'ar-SA' ? 'styles-ar.css' : 'styles-en.css';
    if (existingLink) {
      existingLink.href = bundleName;
    } else {
      const newLink = this.document.createElement('link');
      newLink.rel = 'stylesheet';
      newLink.type = 'text/css';
      newLink.id = 'langCss';
      newLink.href = bundleName;
      headTag.appendChild(newLink);
      lang == 'ar-SA'
        ? this.addCss('styles-ar.css')
        : this.addCss('styles-en.css');

    }
  }

  addCss(fileName) {
    const head = this.document.getElementsByTagName('head')[0];
    const link = this.document.createElement('link');
    const existId = this.document.getElementById('langCss') as HTMLLinkElement;
    if (existId) {
      existId.href = fileName;
    } else {
      link.id = 'langCss';

      link.rel = 'stylesheet';
      link.href = fileName;

      head.appendChild(link);
    }
  }
}
