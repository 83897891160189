import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateLoader, } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppSettings } from 'src/app/core/settings/app-settings';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';

import * as _moment from 'moment';
import { LoginHeaderComponent } from './login-header/login-header.component';
import { PreviewAttachmentComponent } from './preview-attachment/preview-attachment.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';  
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/yyyy',
  },
  display: {
    dateInput: 'DD/MM/yyyy',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const commonModules = [
  CommonModule,
  NgxSpinnerModule,
  FormsModule,
  ReactiveFormsModule


];
const MaterialModules =
  [
    MatSelectModule,
    MatRadioModule,
    MatIconModule,
    MatGridListModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatDialogModule,
    MatTooltipModule,
    ToastrModule.forRoot({ preventDuplicates: true }),
    EditorModule,
    NgSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    NgbModule,
    NgbCollapseModule,
    MatPaginatorModule,
    NgxDocViewerModule
  ]
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const commonComponents = [HeaderComponent, FooterComponent];
@NgModule({
  declarations: [commonComponents, LoginHeaderComponent, PreviewAttachmentComponent, ConfirmDeleteComponent],
  imports: [commonModules, MaterialModules,
    TranslateModule.forRoot({
      defaultLanguage: AppSettings.defaultLang,
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
    })
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },

  ],
  entryComponents: [PreviewAttachmentComponent],
  exports: [commonModules, MaterialModules, TranslateModule],
})
export class SharedModule { }
