import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'app-preview-attachment',
  templateUrl: './preview-attachment.component.html',
  styleUrls: ['./preview-attachment.component.css']
})
export class PreviewAttachmentComponent implements OnInit {
  attatchment: any;
  generatedImage: any;
  tempGeneratedImage: any;
  extension;
  viewer = 'google';
  canPreview = true;
  isImage: boolean = false;
  fileName = null;
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {
    this.attatchment = data;
  }

  ngOnInit(): void {
    this.createBlobImageFileAndShow()
  }

  createBlobImageFileAndShow(): void {
    var ext = this.attatchment.Name.substring(this.attatchment.Name.lastIndexOf('.') + 1).toLowerCase();
    //const Image = this.attatchment.Name.split(".", 2);
    const imageName: string = this.attatchment.Name;
    this.fileName = imageName;
    //  var extension = ext == 'pdf' ? "application/pdf" : "image/" + ext;
    this.extension = this.GetMimeType(ext);

    this.dataURItoBlob(this.attatchment.data, this.extension).subscribe((blob: Blob) => {
      const imageBlob: Blob = blob;

      var file: any;
      file = new File([imageBlob], imageName, {
        type: this.extension
      });
      this.generatedImage = window.URL.createObjectURL(file);
      this.generatedImage = this.sanitizer.bypassSecurityTrustResourceUrl(this.generatedImage);
      debugger;
      if (ext.indexOf('jpeg') > -1 || ext.indexOf('png') > -1 || ext.indexOf('jpg') > -1) {
        this.canPreview = true;
        this.isImage = true;
        return
      }
      if (ext.indexOf('doc') > -1 || ext.indexOf('xl') > -1) {
        this.canPreview = false;
        this.isImage = false;
      } else {
        this.canPreview = true;
        this.isImage = false;
      }

    });
  }

  GetMimeType(extension) {
    var extToMimes = {
      'jpeg': 'image/jpeg',
      'jpg': 'image/jpg',
      'png': 'image/png',
      'docx': "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "doc": "application/msword",
      "dot": "application/msword",
      "xls": "application/vnd.ms-excel",
      "xlt": "application/vnd.ms-excel",
      "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "pdf": "application/pdf"
    }

    return extToMimes[extension];
  }
  /* Method to convert Base64Data Url as Image Blob */
  dataURItoBlob(dataURI: string, extension: string): Observable<Blob> {
    return Observable.create((observer: Observer<Blob>) => {
      const byteString: string = window.atob(dataURI);
      const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: extension });
      observer.next(blob);
      observer.complete();
    });
  }

}
