import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import {SurveyComponent} from 'src/app/modules/survey/survey/survey.component';
const routes: Routes = [

  {
    path: 'manager',
   loadChildren: () =>
   import('./modules/manager/manager.module').then(
     (m) => m.ManagerModule
   ),
  },
 
  {
    path: 'identity',
   
    loadChildren: () =>
      import('./modules/identity/identity.module').then(
        (m) => m.IdentityModule
      )
  },
  {
    path: 'ticket',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/ticket/ticket.module').then(
        (m) => m.TicketModule
      ),
  },
  { path: 'survey', component: SurveyComponent },
  { path: '', redirectTo: '/ticket', pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
