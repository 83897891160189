import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

//https://stackoverflow.com/questions/57976898/how-to-get-mime-type-from-base-64-string?answertab=active#tab-top
//https://base64.guru/converter/encode/hex
//https://en.wikipedia.org/wiki/List_of_file_signatures
@Injectable({ providedIn: 'root' })
export  class HelperService {
   signatures = {
    "JVBERi0": "application/pdf",
    "iVBORw0KGgo": "image/png",
    "UE":"",
    "/9j/":"image/jpg"
  };

  constructor() {
  }

   IsAllowedMimeType(b64) {
    for (var s in this.signatures) {
      if (b64.indexOf(s) === 0) {
        return true;
      }
     

    }
    return false;
  }


}
