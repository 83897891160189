import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderInterceptor } from './core/Interceptors/header.interceptor';
import { SharedModule } from './modules/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccountService, TicketsService } from './services';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import {SurveyComponent} from 'src/app/modules/survey/survey/survey.component';
import {FooterSureveyComponent} from 'src/app/modules/survey/footer/footer.component';
import {AsideComponent} from 'src/app/modules/survey/aside/aside.component';
@NgModule({
  declarations: [AppComponent,
    SurveyComponent,
    FooterSureveyComponent,
    AsideComponent
  ],
  imports: [BrowserModule, AppRoutingModule, SharedModule, HttpClientModule, BrowserAnimationsModule,
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    })],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    },
    AccountService,

  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
