import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Ticket } from '../core/models/Ticket.model';


@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  filter: any;

  constructor(private router: Router, private http: HttpClient) {
  }
  Tickets = new Subject<any>();

  setFilter(filter:any){
    this.filter= filter;
   }
getFilter(){
    return this.filter;
}

  RefreshTickets(Tickets: any) {
    this.Tickets.next(Tickets)
  }

  public GetAllTicketDetails(ticketNumber): Observable<any[]> {
    let response1 = this.GetTicketDetails(ticketNumber);
    let response2 = this.GetComments(ticketNumber);
    let response3 = this.GetTicketAttachments(ticketNumber);
    // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
    return forkJoin([response1, response2, response3]);
  }
  public GetTicketRequest(requestId): Observable<any[]> {
    let response1 = this.GetRequestInfo(requestId);
    let response2 = this.GetRequestAttachments(requestId);
    return forkJoin([response1, response2]);
  }
  GetRequestAttachments(requestId) {

    return this.http
      .get<any>(`${environment.apiUrl}/api/Tickets/${requestId}/RequestAttachments`,)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  GetRequestInfo(requestId) {

    return this.http
      .get<any>(`${environment.apiUrl}/api/Tickets/${requestId}/Request`,)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  GetAll(offset) {
    const source = localStorage.getItem("ticketSource");
    const ID = localStorage.getItem("ID");

    const params = `?offset=${offset}&&Source=${source}&&ID=${ID}`
    return this.http
      .get<any>(`${environment.apiUrl}/api/Tickets${params}`,)
      .pipe(
        map((data) => {

          return data;
        })
      );
  }


  CreateTicket(ticket: Ticket, attachments) {

    const source = localStorage.getItem("ticketSource");
    const ID = localStorage.getItem("ID");
    // if(source=="tpl_ticket")
    //   var Channel="tpl_portal"
    // else
    //   {
    //     var Channel=source;

    //   }
    const request = {

      ID: ID,
      Source: source,
      Subtype: ticket.subType.toString(),
      SubtypeCat: ticket.subTypeCategory.toString(),
      Description: ticket.Description.toString(),
      Channel: "portal",
      AttachmentBase64: attachments
    }



    return this.http
      .post<any>(`${environment.apiUrl}/api/Tickets/PostTicket`, request)
      .pipe(
        map((data) => {

          return data;
        })
      );
  }

  Search(filter, offset) {

    filter.ID = localStorage.getItem("ID");
    filter.Source = localStorage.getItem("ticketSource");
    filter.offset = offset;

    return this.http
      .post<any>(`${environment.apiUrl}/api/Tickets/filter`, filter)
      .pipe(
        map((data) => {

          return data;
        })
      );
  }

  GetTicketDetails(ticketNumber) {
    const Id = localStorage.getItem('ID');
    const source = localStorage.getItem("ticketSource");

    const params = `?ticketNumber=${ticketNumber}&&Source=${source}`
    return this.http
      .get<any>(`${environment.apiUrl}/api/Tickets/${Id}${params}`,)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  GetTicketAttachments(ticketNumber) {
    const Id = localStorage.getItem('ID');
    const source = localStorage.getItem("ticketSource");
    const params = `?ticketNumber=${ticketNumber}&&Source=${source}`
    return this.http
      .get<any>(`${environment.apiUrl}/api/Tickets/${Id}/Attachments${params}`,)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }


  AddComment(ticketNumber, comment: any, Attatchments: any[]) {

    const Id = localStorage.getItem('ID');
    const source = localStorage.getItem("ticketSource");
    const request = {

      ID: Id,
      Source: source,
      Comment: comment,
      ticketNumber: ticketNumber,
      Attachments: Attatchments
    }
    return this.http
      .post<any>(`${environment.apiUrl}/api/Tickets/PostComment`, request)
      .pipe(
        map((data) => {
          return data;
        })
      );


  }

  GetComments(ticketNumber) {
    const Id = localStorage.getItem('ID');
    const source = localStorage.getItem("ticketSource");
    const params = `?Source=${source}&&ID=${Id}`

    return this.http
      .get<any>(`${environment.apiUrl}/api/Tickets/${ticketNumber}/Comments${params}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  CancelTicket(ticketNumber, reason) {
    const Id = localStorage.getItem('ID');
    const source = localStorage.getItem("ticketSource");
    const request = {
      ID: Id,
      Source: source,
      Reason: reason,
      TicketNumber: ticketNumber
    }
    return this.http
      .put<any>(`${environment.apiUrl}/api/Tickets`, request)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  UpdateRequest(requestId, status) {

    const request = {

      RequestId: requestId,
      Status: status,

    }

    return this.http
      .post<any>(`${environment.apiUrl}/api/Tickets/Request`, request)
      .pipe(
        map((data) => {

          return data;
        })
      );
  }
  ValidateTicket()
  {
    const Id = localStorage.getItem('ID');
    const source = localStorage.getItem("ticketSource");
    const params = `?Source=${source}`

    return this.http
      .get<any>(`${environment.apiUrl}/api/Tickets/ValidateTicket/${Id}${params}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  GetTicketSubTypeCategoryTemplate(categoryId)
  {
    const Id = localStorage.getItem('ID');
    const source = localStorage.getItem("ticketSource");
    const params = `?id=${Id}&source=${source}`

    return this.http
      .get<any>(`${environment.apiUrl}/api/Tickets/CategoryTemplate/${categoryId}${params}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
