<div class="page-wrapper" id="survey_wrapper">
  <div class="content-details">
    <app-aside></app-aside>
  </div>
  <div class="content-form">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <h4>یرجى الضغط على رقم مستوى الخدمة المقدمة لكم في البلاغ :</h4>
        </div>

        <!--first question-->
        <form [formGroup]="form" (ngSubmit)="submit()">
          <div class="col-12 col-md-12">
            <div class="row">
              <div class="col-12">
                <h6 for="questionOne">
                  كیف ترى مستوى الخدمة والوقت اللازم لإغلاق البلاغ؟
                </h6>
              </div>
              <div class="col">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="question2"
                    formControlName="question2"
                    id="inlineRadio1"
                    value="1"
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    <div class="rating-deatils">
                      <span>
                        <img
                          src="../../../assets/img/happy.svg"
                          alt="happy-img"
                        />
                      </span>
                      <span>
                        <img
                          src="../../../assets/img/happy-checked.svg"
                          alt="happy-checked-img"
                        />
                      </span>
                      <p>راضي تماما</p>
                    </div>
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="question2"
                    formControlName="question2"
                    id="inlineRadio2"
                    value="2"
                    checked
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    <div class="rating-deatils">
                      <span>
                        <img
                          src="../../../assets/img/smile.svg"
                          alt="smile-img"
                        />
                      </span>
                      <span>
                        <img
                          src="../../../assets/img/smile-checked.svg"
                          alt="smile-checked-img"
                        />
                      </span>
                      <p>راضي</p>
                    </div>
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="question2"
                    formControlName="question2"
                    id="inlineRadio3"
                    value="3"
                  />
                  <label class="form-check-label" for="inlineRadio3">
                    <div class="rating-deatils">
                      <span>
                        <img
                          src="../../../assets/img/acceptable.svg"
                          alt="acceptable-img"
                        />
                      </span>
                      <span>
                        <img
                          src="../../../assets/img/acceptable-checked.svg"
                          alt="acceptable-checked-img"
                        />
                      </span>
                      <p>مقبول</p>
                    </div>
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="question2"
                    formControlName="question2"
                    id="inlineRadio4"
                    value="4"
                  />
                  <label class="form-check-label" for="inlineRadio4">
                    <div class="rating-deatils">
                      <span>
                        <img src="../../../assets/img/sad.svg" alt="sad-img" />
                      </span>
                      <span>
                        <img
                          src="../../../assets/img/sad-checked.svg"
                          alt="sad-checked-img"
                        />
                      </span>
                      <p>غير راضي</p>
                    </div>
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="question2"
                    formControlName="question2"
                    id="inlineRadio5"
                    value="5"
                  />
                  <label class="form-check-label" for="inlineRadio5">
                    <div class="rating-deatils">
                      <span>
                        <img
                          src="../../../assets/img/angry.svg"
                          alt="angry-img"
                        />
                      </span>
                      <span>
                        <img
                          src="../../../assets/img/angury-checked.svg"
                          alt="angry-checked-img"
                        />
                      </span>
                      <p>غير راضي تماما</p>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div
              *ngIf="f.question2.touched && f.question2.invalid"
              class="col-12 alert alert-sm alert-danger"
            >
              لابد من إختيار option.
            </div>
          </div>
          <!--End first question-->
          <!--Second Second-->
          <div class="col-12 col-md-12">
            <div class="row">
              <div class="col-12">
                <h6 for="questionTwo">ما رأیك في نتیجة البلاغ الخاص بكم ؟</h6>
              </div>
              <div class="col">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="question1"
                    formControlName="question1"
                    id="inlineRadio6"
                    value="1"
                    checked
                  />
                  <label class="form-check-label" for="inlineRadio6">
                    <div class="rating-deatils">
                      <span>
                        <img
                          src="../../../assets/img/happy.svg"
                          alt="happy-img"
                        />
                      </span>
                      <span>
                        <img
                          src="../../../assets/img/happy-checked.svg"
                          alt="happy-checked-img"
                        />
                      </span>
                      <p>راضي تماما</p>
                    </div>
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="question1"
                    formControlName="question1"
                    id="inlineRadio7"
                    value="2"
                  />
                  <label class="form-check-label" for="inlineRadio7">
                    <div class="rating-deatils">
                      <span>
                        <img
                          src="../../../assets/img/smile.svg"
                          alt="smile-img"
                        />
                      </span>
                      <span>
                        <img
                          src="../../../assets/img/smile-checked.svg"
                          alt="smile-checked-img"
                        />
                      </span>
                      <p>راضي</p>
                    </div>
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="question1"
                    formControlName="question1"
                    id="inlineRadio8"
                    value="3"
                  />
                  <label class="form-check-label" for="inlineRadio8">
                    <div class="rating-deatils">
                      <span>
                        <img
                          src="../../../assets/img/acceptable.svg"
                          alt="acceptable-img"
                        />
                      </span>
                      <span>
                        <img
                          src="../../../assets/img/acceptable-checked.svg"
                          alt="acceptable-checked-img"
                        />
                      </span>
                      <p>مقبول</p>
                    </div>
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="question1"
                    formControlName="question1"
                    id="inlineRadio9"
                    value="4"
                  />
                  <label class="form-check-label" for="inlineRadio9">
                    <div class="rating-deatils">
                      <span>
                        <img src="../../../assets/img/sad.svg" alt="sad-img" />
                      </span>
                      <span>
                        <img
                          src="../../../assets/img/sad-checked.svg"
                          alt="sad-checked-img"
                        />
                      </span>
                      <p>غير راضي</p>
                    </div>
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="question1"
                    formControlName="question1"
                    id="inlineRadio10"
                    value="5"
                  />
                  <label class="form-check-label" for="inlineRadio10">
                    <div class="rating-deatils">
                      <span>
                        <img
                          src="../../../assets/img/angry.svg"
                          alt="angry-img"
                        />
                      </span>
                      <span>
                        <img
                          src="../../../assets/img/angury-checked.svg"
                          alt="angry-checked-img"
                        />
                      </span>
                      <p>غير راضي تماما</p>
                    </div>
                  </label>
                </div>
              </div>

              <div
                *ngIf="f.question1.touched && f.question1.invalid"
                class="col-12 alert alert-sm alert-danger"
              >
                لابد من إختيار option.
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <h6 for="textArea">
                هل لديكم ملاحظات لتحسين مستوى الخدمة المقدمة؟
              </h6>
              <textarea
                class="form-control"
                formControlName="enhancementNote"
                id="exText"
                placeholder="شكرا لكم لحسن مستوي الخدمة ونرجوا لكم التوفيق"
              ></textarea>
            </div>
          </div>

          <div
            *ngIf="f.enhancementNote.touched && f.enhancementNote.invalid"
            class="col-12 alert alert-sm alert-danger"
          >
            يرجي ادخال الملاحظات
          </div>

          <!--End Second question-->
          <div class="col-12">
            <button class="btn" type="submit">
              إرسال
            </button>
          </div>
        </form>

      
      </div>
    </div> 
  </div>
  <app-survey-footer></app-survey-footer>
</div>
