import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { AccountService } from 'src/app/services';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private accountService: AccountService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
     
    const user =localStorage.getItem("token");
    if (user) {
      // authorised so return true
      return true;
    }
    
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/identity'], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}
