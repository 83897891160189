<div class="logo">
  <img src="../../../assets/img/logo.png" alt="logo" />
</div>
<div class="description">
  <h3>
    نشكر لكم تواصلكم مع مركز بلاغات وزارة النقل - مركز المستفیدین – الھیئة
    العامة للموانئ ونود مشاركتكم في تقييم مستوى الخدمة المقدمة لكم
  </h3>
  <p class="col-12 text-cenetr">
    جميع الحقوق محفوظة 1442هـ | 2021 م وزارة النقل المملكة العربية السعودية
  </p>
</div>
