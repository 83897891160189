<div class="modal-header">
    <h4 class="modal-title justify-content-center align-items-center d-flex text-blue font--size__22">
        <img class="mr-3 ml-0" src="../assets/validating-ticket.png" />
        <span> {{"confirm delete" |translate}}</span>
    </h4>
</div>
<div mat-dialog-content>
    <p>{{'you are about delete the input content' | translate}} </p>

</div>
<div mat-dialog-actions class="flex-nowrap">
    <button mat-button class="btn pl-5 pr-5 pt-3 pb-3 font--size__13 border_radius-5 cancel font--bold"
        (click)="onNoClick()">{{'cancel'|translate}}</button>
    <button
        class="btn font--bold background-darkblue text--white pl-5 pr-5 pt-3 pb-3 font--size__13 border_radius-5 ml-3"
        mat-button [mat-dialog-close]="data" cdkFocusInitial>{{'Ok'|translate}}</button>


</div>